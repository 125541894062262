<script>
import { mapGetters, mapState } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import APIAffiliate from '@app/services/API/Affiliate';
import AffiliateForm from '@app/components/AffiliateForm.vue';
import AffiliatesStatList from '@app/components/AffiliatesStatList.vue';
import AffiliatesPendingPayoutsList from '@app/components/AffiliatesPendingPayoutsList.vue';

export default {
  mixins: [utilsMixin],
  components: {
    AffiliateForm,
    AffiliatesStatList,
    AffiliatesPendingPayoutsList,
  },
  data: () => ({
    q: '',
    isFetching: false,
    isLoading: false,
    affiliateShowing: null,
    editingAffiliate: null,
    hasAffiliateFormOpen: false,
    hasAffiliateCardOpen: false,
  }),
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
    ...mapState('affiliates', {
      affiliates: 'list',
    }),
    canSeeWelcomeMessage() {
      return (
        this.affiliates.data.length <= 0
        && this.$store.getters['auth/isAffiliationClosed']
      );
    },
  },
  methods: {
    searchAffiliates() {
      this.getAffiliates(1, false, { q: this.q || undefined });
    },
    getAffiliates(page = 1, firstFetch = false, params = {}) {
      if (firstFetch) {
        this.isFetching = true;
      } else {
        this.isLoading = true;
      }

      this.$store.dispatch('affiliates/getList', {
        storeUUID: this.authStore.uuid,
        params: { page, ...params },
      })
        .finally(() => {
          if (firstFetch) {
            this.isFetching = false;
          } else {
            this.isLoading = false;
          }
        });
    },
    showAffiliate(customerAffiliate) {
      this.$router.push({
        name: 'affiliate',
        params: { uuid: customerAffiliate.uuid },
      });
    },
    onAffiliateCardClose() {
      this.hasAffiliateCardOpen = false;
      this.customerAffiliate = null;
    },
    onAffiliateAdd(customerAffiliate) {
      this.hasAffiliateFormOpen = false;
      this.$store.commit('affiliates/addToList', customerAffiliate);
    },
    onAffiliateEdit(customerAffiliate) {
      this.$router.push({
        name: 'affiliate_edit',
        params: { uuid: customerAffiliate.uuid },
      });
    },
    activateAffiliate(affiliate) {
      const loader = this.$buefy.loading.open();
      APIAffiliate.updateAffiliate(
        this.authStore.uuid,
        affiliate.uuid,
        { status: 'ACTIVE' },
      )
        .then(({ data }) => this.updateAffiliateInList(
          data, affiliate,
        ))
        .finally(() => loader.close());
    },
    suspendAffiliate(affiliate) {
      const loader = this.$buefy.loading.open();
      APIAffiliate.updateAffiliate(
        this.authStore.uuid,
        affiliate.uuid,
        { status: 'SUSPENDED' },
      )
        .then(({ data }) => this.updateAffiliateInList(
          data, affiliate,
        ))
        .finally(() => loader.close());
    },
    updateAffiliateInList(data, affiliate) {
      this.affiliates.data = this.affiliates
        .data
        .map((v) => {
          if (v.uuid === affiliate.uuid) {
            const updated = { ...v };
            updated.status = data.status;
            return updated;
          }

          return v;
        });
    },
  },
  created() {
    this.getAffiliates(1, true);
  },
};
</script>

<template>
  <div>
    <AffiliatesStatList />
    <AffiliatesPendingPayoutsList class="mt-10" />

    <section class="mt-10">
      <header class="level mb-5">
        <h2 class="title is-4 mb-0">
          Liste des affiliés
        </h2>
        <div class="is-flex">
          <form @submit.prevent="searchAffiliates">
            <b-field>
              <b-input placeholder="Rechercher" v-model="q" />
              <p class="control">
                <b-button
                  type="is-primary"
                  icon-left="search"
                  native-type="submit"
                />
              </p>
            </b-field>
          </form>
          <b-button
            class="ml-3"
            type="is-primary"
            @click="hasAffiliateFormOpen = true"
          >
            Ajouter un affilié
          </b-button>
        </div>
      </header>
      <b-skeleton v-if="isFetching" height="400" />
      <div v-else-if="canSeeWelcomeMessage" class="box content">
        <p>
          Vous n'avez pas encore d'affilié.
        </p>
        <p>
          Pour lancer votre programme d'affiliation et augmenter vos ventes automatiquement, accédez à la configuration !
        </p>
        <p>
          <b-button
            class="mt-4"
            type="is-primary"
            tag="router-link"
            :to="{name: 'affiliates_settings'}"
          >
            Configurer mon programme d'affiliation
          </b-button>
        </p>
      </div>
      <b-table
        v-else
        class="box"
        :data="affiliates.data"
        :loading="isLoading"
        :per-page="affiliates.meta.per_page"
        :total="affiliates.meta.total"
        backend-pagination
        paginated
        @page-change="getAffiliates($event)"
      >
        <b-table-column
          label="Affilié"
          field="firstname"
          width="320"
          v-slot="{ row }"
        >
          <div>
            <p class="break-words">
              <router-link
                class="has-text-black"
                :to="{name: 'affiliate', params: {uuid: row.uuid}}"
              >
                {{ row.customer.firstname }} {{ row.customer.lastname }}
              </router-link>
            </p>
            <p class="mt-1 break-words">
              <router-link
                class="has-text-black"
                :to="{name: 'affiliate', params: {uuid: row.uuid}}"
              >
                <strong>{{ row.customer.email }}</strong>
              </router-link>
            </p>
          </div>
        </b-table-column>
        <b-table-column
          label="Devenu affilié"
          field="created_at"
          width="160"
          numeric
          v-slot="{ row }"
        >
          <div>
            <p>
              <b-tooltip>
                <template #content>
                  {{ row.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                {{ row.created_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </p>
            <p
              :class="{
                'has-text-success': row.status === 'ACTIVE',
                'has-text-danger': row.status === 'SUSPENDED',
              }"
              v-t="`affiliate.status.${row.status}`"
            />
          </div>
        </b-table-column>
        <b-table-column
          label="Ventes"
          field="total_sales"
          v-slot="{ row }"
        >
          <div>
            <p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.stat_description.total_sales'" />
                </template>
                {{ row.total_sales }}
                vente{{ row.total_sales > 1 ? 's' : '' }}
              </b-tooltip>
            </p>
            <p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.stat_description.total_visits'" />
                </template>
                {{ row.total_visits }}
                visite{{ row.total_visits > 1 ? 's' : '' }}
              </b-tooltip>
            </p>
            <p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.stat_description.total_conversion'" />
                </template>
                {{ [row.total_sales, row.total_visits] | toPercentage }}%
                de conversion
              </b-tooltip>
            </p>
          </div>
        </b-table-column>
        <b-table-column
          label="Commissions"
          field="total_amount"
          v-slot="{ row }"
        >
          <div>
            <p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.stat_description.total_amount'" />
                </template>
                {{ row.total_amount }} &euro;
                 généré{{ row.total_amount > 1 ? 's' : '' }}
              </b-tooltip>
            </p>
            <p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.stat_description.total_paid'" />
                </template>
                {{ row.total_paid }} &euro;
                payé{{ row.total_paid > 1 ? 's' : '' }}
              </b-tooltip>
            </p>
            <p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.stat_description.total_unpaid'" />
                </template>
                {{ row.total_amount - row.total_paid }} &euro;
                dû
              </b-tooltip>
            </p>
            <p v-if="row.total_visits > 0">
              Taux de conversion :
              <strong>
                {{ [row.total_sales, row.total_visits] | toPercentage }}%
              </strong>
            </p>
          </div>
        </b-table-column>
        <b-table-column width="50" v-slot="{ row }">
          <b-dropdown position="is-bottom-left">
            <template #trigger>
              <b-button
                type="is-text"
                icon-left="ellipsis-h"
              />
            </template>
            <b-dropdown-item @click="showAffiliate(row)">
              Voir la fiche affilié
            </b-dropdown-item>
            <b-dropdown-item
              @click="$router.push({
                name: 'customer',
                params: { uuid: row.customer.uuid }
              })"
            >
              Voir la fiche apprenant
            </b-dropdown-item>
            <b-dropdown-item @click="onAffiliateEdit(row)">
              Modifier
            </b-dropdown-item>
            <b-dropdown-item separator />
            <b-dropdown-item
              v-if="row.status === 'SUSPENDED'"
              class="has-text-success"
              @click="activateAffiliate(row)"
            >
              Activer
              <b-tooltip class="ml-3" position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.status_explanation.ACTIVE'" />
                </template>
                <b-icon icon="info-circle" />
              </b-tooltip>
            </b-dropdown-item>
            <b-dropdown-item
            v-if="row.status === 'ACTIVE'"
              class="has-text-danger"
              @click="suspendAffiliate(row)"
            >
              Suspendre
              <b-tooltip class="ml-3" position="is-left" multilined>
                <template #content>
                  <span v-t="'affiliate.status_explanation.SUSPENDED'" />
                </template>
                <b-icon icon="info-circle" />
              </b-tooltip>
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
        <template #empty>
          <p>
            Vous n'avez pas encore d'affilié.
          </p>
        </template>
      </b-table>
    </section>

    <b-modal
      v-if="hasAffiliateFormOpen"
      :can-cancel="false"
      hasModalCard
      active
    >
      <AffiliateForm
        @create="onAffiliateAdd"
        @cancel="hasAffiliateFormOpen = false"
      />
    </b-modal>

    <router-view />
  </div>
</template>
