var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AffiliatesStatList'),_c('AffiliatesPendingPayoutsList',{staticClass:"mt-10"}),_c('section',{staticClass:"mt-10"},[_c('header',{staticClass:"level mb-5"},[_c('h2',{staticClass:"title is-4 mb-0"},[_vm._v(" Liste des affiliés ")]),_c('div',{staticClass:"is-flex"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchAffiliates($event)}}},[_c('b-field',[_c('b-input',{attrs:{"placeholder":"Rechercher"},model:{value:(_vm.q),callback:function ($$v) {_vm.q=$$v},expression:"q"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"search","native-type":"submit"}})],1)],1)],1),_c('b-button',{staticClass:"ml-3",attrs:{"type":"is-primary"},on:{"click":function($event){_vm.hasAffiliateFormOpen = true}}},[_vm._v(" Ajouter un affilié ")])],1)]),(_vm.isFetching)?_c('b-skeleton',{attrs:{"height":"400"}}):(_vm.canSeeWelcomeMessage)?_c('div',{staticClass:"box content"},[_c('p',[_vm._v(" Vous n'avez pas encore d'affilié. ")]),_c('p',[_vm._v(" Pour lancer votre programme d'affiliation et augmenter vos ventes automatiquement, accédez à la configuration ! ")]),_c('p',[_c('b-button',{staticClass:"mt-4",attrs:{"type":"is-primary","tag":"router-link","to":{name: 'affiliates_settings'}}},[_vm._v(" Configurer mon programme d'affiliation ")])],1)]):_c('b-table',{staticClass:"box",attrs:{"data":_vm.affiliates.data,"loading":_vm.isLoading,"per-page":_vm.affiliates.meta.per_page,"total":_vm.affiliates.meta.total,"backend-pagination":"","paginated":""},on:{"page-change":function($event){return _vm.getAffiliates($event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v(" Vous n'avez pas encore d'affilié. ")])]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Affilié","field":"firstname","width":"320"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',{staticClass:"break-words"},[_c('router-link',{staticClass:"has-text-black",attrs:{"to":{name: 'affiliate', params: {uuid: row.uuid}}}},[_vm._v(" "+_vm._s(row.customer.firstname)+" "+_vm._s(row.customer.lastname)+" ")])],1),_c('p',{staticClass:"mt-1 break-words"},[_c('router-link',{staticClass:"has-text-black",attrs:{"to":{name: 'affiliate', params: {uuid: row.uuid}}}},[_c('strong',[_vm._v(_vm._s(row.customer.email))])])],1)])]}}])}),_c('b-table-column',{attrs:{"label":"Devenu affilié","field":"created_at","width":"160","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_c('b-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.created_at),'DD/MM/YY [à] HH:mm'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.created_at),'from'))+" ")])],1),_c('p',{directives:[{name:"t",rawName:"v-t",value:(("affiliate.status." + (row.status))),expression:"`affiliate.status.${row.status}`"}],class:{
              'has-text-success': row.status === 'ACTIVE',
              'has-text-danger': row.status === 'SUSPENDED',
            }})])]}}])}),_c('b-table-column',{attrs:{"label":"Ventes","field":"total_sales"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',[_c('p',[_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.stat_description.total_sales'),expression:"'affiliate.stat_description.total_sales'"}]})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(row.total_sales)+" vente"+_vm._s(row.total_sales > 1 ? 's' : '')+" ")])],1),_c('p',[_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.stat_description.total_visits'),expression:"'affiliate.stat_description.total_visits'"}]})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(row.total_visits)+" visite"+_vm._s(row.total_visits > 1 ? 's' : '')+" ")])],1),_c('p',[_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.stat_description.total_conversion'),expression:"'affiliate.stat_description.total_conversion'"}]})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("toPercentage")([row.total_sales, row.total_visits]))+"% de conversion ")])],1)])]}}])}),_c('b-table-column',{attrs:{"label":"Commissions","field":"total_amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',[_c('p',[_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.stat_description.total_amount'),expression:"'affiliate.stat_description.total_amount'"}]})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(row.total_amount)+" € généré"+_vm._s(row.total_amount > 1 ? 's' : '')+" ")])],1),_c('p',[_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.stat_description.total_paid'),expression:"'affiliate.stat_description.total_paid'"}]})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(row.total_paid)+" € payé"+_vm._s(row.total_paid > 1 ? 's' : '')+" ")])],1),_c('p',[_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.stat_description.total_unpaid'),expression:"'affiliate.stat_description.total_unpaid'"}]})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(row.total_amount - row.total_paid)+" € dû ")])],1),(row.total_visits > 0)?_c('p',[_vm._v(" Taux de conversion : "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("toPercentage")([row.total_sales, row.total_visits]))+"% ")])]):_vm._e()])]}}])}),_c('b-table-column',{attrs:{"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"type":"is-text","icon-left":"ellipsis-h"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.showAffiliate(row)}}},[_vm._v(" Voir la fiche affilié ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({
              name: 'customer',
              params: { uuid: row.customer.uuid }
            })}}},[_vm._v(" Voir la fiche apprenant ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.onAffiliateEdit(row)}}},[_vm._v(" Modifier ")]),_c('b-dropdown-item',{attrs:{"separator":""}}),(row.status === 'SUSPENDED')?_c('b-dropdown-item',{staticClass:"has-text-success",on:{"click":function($event){return _vm.activateAffiliate(row)}}},[_vm._v(" Activer "),_c('b-tooltip',{staticClass:"ml-3",attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.status_explanation.ACTIVE'),expression:"'affiliate.status_explanation.ACTIVE'"}]})]},proxy:true}],null,true)},[_c('b-icon',{attrs:{"icon":"info-circle"}})],1)],1):_vm._e(),(row.status === 'ACTIVE')?_c('b-dropdown-item',{staticClass:"has-text-danger",on:{"click":function($event){return _vm.suspendAffiliate(row)}}},[_vm._v(" Suspendre "),_c('b-tooltip',{staticClass:"ml-3",attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('affiliate.status_explanation.SUSPENDED'),expression:"'affiliate.status_explanation.SUSPENDED'"}]})]},proxy:true}],null,true)},[_c('b-icon',{attrs:{"icon":"info-circle"}})],1)],1):_vm._e()],1)]}}])})],1)],1),(_vm.hasAffiliateFormOpen)?_c('b-modal',{attrs:{"can-cancel":false,"hasModalCard":"","active":""}},[_c('AffiliateForm',{on:{"create":_vm.onAffiliateAdd,"cancel":function($event){_vm.hasAffiliateFormOpen = false}}})],1):_vm._e(),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }